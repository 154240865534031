$('document').ready(function()
{
    var disabledDates = $('#booking__calendar').data('disableddates');
    var arrivalDates = $('#booking__calendar').data('arrivals');
    var departureDates = $('#booking__calendar').data('departures');

    function initializeCalendar( d )
    {
        var date1 = $.datepicker.parseDate("dd/mm/yy", $("#arrival").val());
        var date2 = $.datepicker.parseDate("dd/mm/yy", $("#departure").val());

        if ( arrivalDates.indexOf( getDate( d ) ) > -1 )
        {
            return [true, date1 && ((d.getTime() == date1.getTime()) || (date2 && d >= date1 && d <= date2)) ? "arrival dp-highlight" : "arrival"];
        }
        else if ( departureDates.indexOf( getDate( d ) ) > -1 )
        {
            return [true, date1 && ((d.getTime() == date1.getTime()) || (date2 && d >= date1 && d <= date2)) ? "departure dp-highlight" : "departure"];

        }
        else if( disabledDates.indexOf( getDate( d ) ) > -1 )
        {
            return [false, "booked"];
        }
        else
        {
            return [true, date1 && ((d.getTime() == date1.getTime()) || (date2 && d >= date1 && d <= date2)) ? "dp-highlight" : ""];
        }
    }

    function getDate( d )
    {
        var day, month, year;
        day = d.getDate( );
        month = d.getMonth( ) + 1;
        year = d.getFullYear( );

        if( month < 10 ) month = "0" + month;
        if( day < 10 ) day = "0" + day;
        return day + "/" + month + "/" + year;
    }

    $('#booking__calendar').datepicker({
        minDate: 0,
        constrainInput: false,
        dateFormat: 'dd/mm/yy',
        onSelect: function(dateText)
        {
            $('#makeBooking').hide().find('input:not([name="id"]):not([name="_token"])').val('');
            $('.h5__success').hide().find('span').text('');
            $('.h5__error').hide().text('');

            var date1 = $.datepicker.parseDate("dd/mm/yy", $("#arrival").val());
            var date2 = $.datepicker.parseDate("dd/mm/yy", $("#departure").val());
            if (!date1 || date2) {
                $("#arrival").val(dateText);
                $('input[name=_arrival]').val(dateText);
                $("#departure").val("");
            } else {
                $("#departure").val(dateText);
                $('input[name=_departure]').val(dateText);
            }
        },
        beforeShowDay: initializeCalendar,
    }).datepicker('setDate', $("#arrival").val()? $.datepicker.parseDate("dd/mm/yy", $("#arrival").val()): null)
    .datepicker('option', $.datepicker.regional[$('#booking__calendar').data('locale')]);

    // Booking calculation
    $('#calculateBooking').submit(function(e)
    {
        var $this = $(this);
        var $bookingForm = $('#makeBooking');
        var arrival = $this.find('input[name=arrival]').val();
        var departure = $this.find('input[name=departure]').val();
        var persons = $this.find('[name=persons] option:selected').val();

        $this.find('button').addClass('disabled').attr('disabled', true);

        $bookingForm.hide().find('input:not([name="id"]):not([name="_token"])').val('');
        $this.find('.h5__success').hide().find('span').text('');
        $this.find('.h5__error').hide().text('');

        $.ajax({
            type: "POST",
            url: $(this).attr('action'),
            data: $("#calculateBooking").serialize(),
            dataType: 'json',
            cache: false,
            success: function(response)
            {
                if (response.available === true)
                {
                    $this.find('.h5__success span').text(response.data.cost);
                    $this.find('.h5__success').show();
                    $bookingForm.show();
                    $bookingForm.find('input[name=arrival]').val(response.data.arrival);
                    $bookingForm.find('input[name=departure]').val(response.data.departure);
                    $bookingForm.find('input[name=persons]').val(response.data.persons); 
                }
                else if (response.available === false)
                {
                    $this.find('.h5__error').text(response.msg).show();
                    $bookingForm.hide();
                }

                $this.find('button').removeClass('disabled').attr('disabled', false);
            }
        });

        e.preventDefault();
    })
});